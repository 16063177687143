import React from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';

import productScreenshot from '../../../images/datastudio_screenshot.png';
import PageLayout from '../../../layouts/Page';
import ThankYouSmallLogo from '../../../images/small_logo.svg';

const features = [
  {
    name: 'Dashboard personnalisé',
    description: 'Affichez uniquement les données dont vous avez besoin.',
  },
  {
    name: 'Multi-sources',
    description: "Croisez les données TY Marketing avec les données d'autres connecteurs.",
  },
  {
    name: 'Data Visualisation',
    description: 'Choisissez le graphique le plus cohérent en fonction de vos enjeux',
  },
  {
    name: 'Partage',
    description: 'Partagez vos rapports Google Data Studio en interne et en externe',
  },
];

const steps = [
  {
    name: "S'identifier",
    description: 'Utilisez vos informations de connection à votre compte TY Marketing.',
  },
  {
    name: 'Activer',
    description: "Activez le connecteur TY Marketing au sein de l'interface Google Data Studio",
  },
  {
    name: 'Créer un rapport',
    description: 'Créez votre premier rapport et choisissez les indicateurs dont vous avez besoin.',
  },
];

const Page = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Importez vos données TY Marketing dans Google Data Studio.</title>
        <meta
          name="description"
          content="Créez des tableaux de bord multi-sources et complètement personnalisés en quelques clics."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Marketing - Google Data Studio</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              Importez vos données TY Marketing dans Google Data Studio.
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              Créez des tableaux de bord multi-sources et complètement personnalisés en quelques
              clics.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white sm:-mb-96">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <img className="mx-auto" src={productScreenshot} alt="Analytics" />
        </div>
      </div>
      <div className="bg-gray-50 sm:pt-96">
        <div className="max-w-7xl mx-auto pt-8 pb-16 px-4 sm:px-6 lg:pb-24 lg:pt-8 lg:px-8">
          <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-2 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-brand" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl text-center flex flex-col items-center mx-auto py-12 px-4 sm:px-6 md:py-16 lg:px-8 lg:py-20">
          <h2 className="text-xl font-title font-extrabold tracking-tight text-gray-900 sm:text-3xl">
            <span className="block text-green-brand">
              Unifiez vos données Marketing avec TY Marketing & Google Data Studio
            </span>
          </h2>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {steps.map((step, index) => (
                <div key={step.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-gray-700 rounded-md shadow-lg">
                          <span className="h-6 w-6 text-white">{index + 1}</span>
                        </span>
                      </div>
                      <p className="mt-5 text-base text-gray-500">{step.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Page;
